header {
  background-color:#1C1C1C;
  color:white;
  height:20%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin-bottom:5px;
}

.header-row {
  display:flex;
  line-height:0;
  padding:5px 20px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  text-transform:uppercase;
  font-weight:500;
  font-size:40px;
  flex: 1;
}

.instructionContainer {
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: flex-end;
}

.instructionText {
  align-self: center;
  font-family:'Space Mono', monospace;
  font-size:1em;
  font-weight:400;
  padding-right: 10px;
}

.dateSelectorsContainer {
  flex: 5;
}

@media (orientation: landscape) and (max-width: 736px) {
   .title {
      font-size:24px;
   }

   .icon {
    width:20px;
   }

   .header-row {
    padding:0 10px;
   }
}
